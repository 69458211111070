<header class="micro-nav fixed-top">
  <nav class="navbar navbar-expand-lg navbar-light bg-white micro-navbar">
    <a class="navbar-brand" href="#">
      <img style="background-color: #000000;" src="../../assets/images/Logo/elene-logo.png" class="logo" />
      <!--<h5 class="h5-title">Gardens By The Brook</h5>-->
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navcollapse">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navcollapse">
      <ul class="navbar-nav nav-fill menu">
        <li class="nav-item">
          <a class="nav-link active" href="#home"><i class="fa fa-home nav-icon"></i><span
              class="d-sm-inline d-md-none"> Home</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#sitefloorplan"><i class="fa fa-floppy-o nav-icon" aria-hidden="true"></i> Site
            &amp; Floor Plan</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#pricing"><i class="fa fa-money nav-icon" aria-hidden="true"></i> Price</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#gallery"><i class="fa fa-sticky-note nav-icon"></i> Gallery</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#amenities"><i class="fa fa-wifi nav-icon" aria-hidden="true"></i> Amenities</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" href="#sitevisit"><i class="fa fa-youtube-play nav-icon"></i> Virtual Site Tour</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#location"><i class="fa fa-map-marker nav-icon"></i> Location</a>
        </li>
        <li class="nav-item overflow-hidden">
          <a class="nav-link enqModal" href="#" data-toggle="modal" data-target="#myModal"><i
              class="fa fa-download nav-icon d-inline-block animated slideInDown infinite"></i>
            Brochure</a>
        </li>
      </ul>
    </div>
  </nav>
</header>

<main>
  <div class="main-content">
    <div id="home" class="carousel slide micro-main-slider" data-ride="carousel">
      <!-- Indicators -->
      <ul class="carousel-indicators">
        <li data-target="#home" data-slide-to="0" class="active"></li>
        <li data-target="#home" data-slide-to="1"></li>
        <li data-target="#home" data-slide-to="2"></li>
      </ul>

      <!-- The slideshow -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../assets/images/sliding-images/image-1.jpg" alt="Los Angeles" />
        </div>
        <div class="carousel-item">
          <img src="../../assets/images/sliding-images/image-2.jpg" alt="Los Angeles" />
        </div>
        <div class="carousel-item">
          <img src="../../assets/images/sliding-images/image-3.jpg" alt="Los Angeles" />
        </div>
      </div>

      <!-- Left and right controls -->
      <a class="carousel-control-prev" href="#home" data-slide="prev">
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a class="carousel-control-next" href="#home" data-slide="next">
        <span class="carousel-control-next-icon"></span>
      </a>
    </div>

    <div class="whatsapp-icon">
      <a
        onclick="window.open('https://api.whatsapp.com/send?phone=+917207184624&amp;text=Hi! I\'m Interested Lansum Elena Kokapet, Hyderabad. Please share me the details.', '_blank');">
        <img src="../../../assets/WhatsApp_Icon.png" class="logo" />
      </a>
    </div>

    <div class="info-box overflow-hidden text-center">
      <span class="pro-status" style="color: white" data-toggle="modal" data-target="#myModal">Bookings Open</span>
      <div class="info-box-border">
        <span class="pro-title">Lansum Elena</span>
        <span class="pro-add">Kokapet, Hyderabad</span>
        <span class="pro-dev">By Lansum Properties</span>
      </div>
      <ul class="pro-spec roboto-bold">
        <li>
          <span class="heading">Land Acres</span> : 4.19 Acres 
        </li>
        <li>
          <span class="heading">Towers</span> : 2
        </li>
        <li>
          <span class="heading">Units</span> : 540 
        </li>
      </ul>
      <span class="d-block mt-1 text-capitalize bold mb-1 exclusive">
        <div>
          <span class="d-block text-center" style="color: #fff">
            Exclusive Offers 
            <hr />
            Booking Amount 10% Only.
            <hr />
            3 & 4 BHKs <br />
          </span>
        </div>
      </span> 
      <span class="pro-tag-line mt-1">Premium 3 BHK Starts</span>
      <span class="pro-price mb-1"><span class="color-primary roboto-bold" style="font-weight: bolder"><i
            class="mi mi-rs-light info-icon"></i> 2.75 Cr*
          <small>Onwards</small></span></span>
      <button class="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale mb-1" data-toggle="modal"
        data-target="#myModal">
        Enquire Now
      </button>
      <span class="pro-rera"><span class="heading">Rera</span> : <br />
      TS RERA Number - P02400007478 <br />
      </span>
    </div>
    <section class="section shadow-sm lazyloaded" id="overview">
      <span class="head text-capitalize d-none d-md-block">Overview</span>
      <div class="overview">
        <span class="d-block section-heading color-primary text-capitalize">Lansum Elena</span>
        <span class="d-block section-heading-sub color-primary text-capitalize">Kokapet, Hyderabad</span>
        <p>
          Lansum Elena is positioned at posh locale of Kokapet in Hyderabad. Luxurious sky kissed apartments 
          providing a best level of lifestyle with futuristic amenities. Rising majestically, the 55+ storey 
          skyscraper towers over the cityscape, offering a panoramic view of Hyderabad’s skyline. Designed by 
          the esteemed architectural firm RSP Design Consultants, Lansum Elena exemplifies innovation and 
          sustainability. The project’s avant-garde design embraces the concept of sky villas, presenting 
          residents with expansive living spaces that seamlessly blend indoor comfort with outdoor vistas.
            <br>
            <br>
          Convenience is paramount at Lansum Elena, with the finest schools, hospitals, and entertainment hubs 
          just a stone’s throw away. Commuting is effortless, thanks to easy access to the Outer Ring Road, 
          ensuring a quick 25-minute drive to the airport and seamless connectivity to the Financial District.
        </p>
        <button class="btn btn-sm btn-info effetGradient effectScale sectio-bro-btn overflow-hidden enqModal"
          data-toggle="modal" data-target="#myModal">
          <i class="fa fa-download nav-icon d-inline-block animated slideInDown infinite"></i>
          Download Brochure
        </button>
      </div>
    </section>

    <section class="section shadow-sm lazyloaded">
      <span class="section-link" id="sitefloorplan"></span>
      <span class="head text-capitalize">Site &amp; Floor Plan</span>
      <div class="row">
        <!-- <div class="col-md-4">
          <span class="d-block section-heading-sub text-capitalize mt-3">Master Plan</span>
          <a data-lightbox="photos" href="../../assets/images/site-floor/masterplan_1.png">
            <div class="at-property-item mt-1">
              <div class="at-property-img master-plan text-center lazyloaded" data-expand="-1">
                <img src="../../assets/images/site-floor/masterplan_1.png" />

                <div class="at-property-overlayer"></div>
                <span class="at-property-btn" data-toggle="modal" data-target="#myModal">View Master Plan</span>
              </div>
            </div>
          </a>
        </div> -->
        <div class="col-md-8">
          <span class="d-block section-heading-sub text-capitalize mt-3">Floor Plan</span>
          <div class="row">
            <div class="col-lg-4">
              <a href="#" class="text-decoration-none enqModal" data-toggle="modal" data-target="#enqModal">
                <div class="at-property-item shadow-sm border border-grey mt-1">
                  <div class="at-property-img lazyloaded" data-expand="-1">
                    <img class="floor-plan-img" src="../../assets/images/site-floor/Plans_page-0001.jpg" />

                    <div class="at-property-overlayer"></div>
                    <span class="btn btn-default at-property-btn" role="button" data-toggle="modal"
                      data-target="#myModal">Enquire Now</span>
                  </div>
                  <div class="at-property-dis effetGradient">
                    <h5>4 BHK-3755 sq.ft</h5>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-lg-4">
              <a href="#" class="text-decoration-none enqModal" data-toggle="modal" data-target="#enqModal">
                <div class="at-property-item shadow-sm border border-grey mt-1">
                  <div class="at-property-img lazyloaded" data-expand="-1">
                    <img class="floor-plan-img" src="../../assets/images/site-floor/Plans_page-0002.jpg" />

                    <div class="at-property-overlayer"></div>
                    <span class="btn btn-default at-property-btn" role="button" data-toggle="modal"
                      data-target="#myModal">Enquire Now</span>
                  </div>
                  <div class="at-property-dis effetGradient">
                    <h5>4 BHK-3775 sq.ft</h5>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-lg-4">
              <a href="#" class="text-decoration-none enqModal" data-toggle="modal" data-target="#enqModal">
                <div class="at-property-item shadow-sm border border-grey mt-1">
                  <div class="at-property-img lazyloaded" data-expand="-1">
                    <img class="floor-plan-img" src="../../assets/images/site-floor/Plans_page-0003.jpg" />

                    <div class="at-property-overlayer"></div>
                    <span class="btn btn-default at-property-btn" role="button" data-toggle="modal"
                      data-target="#myModal">Enquire Now</span>
                  </div>
                  <div class="at-property-dis effetGradient">
                    <h5>3 BHK-2640 sq.ft</h5>
                  </div>
                </div>
              </a>
            </div>
            
            <div class="col-lg-4">
              <a href="#" class="text-decoration-none enqModal" data-toggle="modal" data-target="#enqModal">
                <div class="at-property-item shadow-sm border border-grey mt-1">
                  <div class="at-property-img lazyloaded" data-expand="-1">
                    <img class="floor-plan-img" src="../../assets/images/site-floor/Plans_page-0004.jpg" />

                    <div class="at-property-overlayer"></div>
                    <span class="btn btn-default at-property-btn" role="button" data-toggle="modal"
                      data-target="#myModal">Enquire Now</span>
                  </div>
                  <div class="at-property-dis effetGradient">
                    <h5>4 BHK-3260 sq.ft</h5>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-lg-4">
              <a href="#" class="text-decoration-none enqModal" data-toggle="modal" data-target="#enqModal">
                <div class="at-property-item shadow-sm border border-grey mt-1">
                  <div class="at-property-img lazyloaded" data-expand="-1">
                    <img class="floor-plan-img" src="../../assets/images/site-floor/Plans_page-0005.jpg" />

                    <div class="at-property-overlayer"></div>
                    <span class="btn btn-default at-property-btn" role="button" data-toggle="modal"
                      data-target="#myModal">Enquire Now</span>
                  </div>
                  <div class="at-property-dis effetGradient">
                    <h5>4 BHK-3465 sq.ft</h5>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-lg-4">
              <a href="#" class="text-decoration-none enqModal" data-toggle="modal" data-target="#enqModal">
                <div class="at-property-item shadow-sm border border-grey mt-1">
                  <div class="at-property-img lazyloaded" data-expand="-1">
                    <img class="floor-plan-img" src="../../assets/images/site-floor/Plans_page-0006.jpg" />

                    <div class="at-property-overlayer"></div>
                    <span class="btn btn-default at-property-btn" role="button" data-toggle="modal"
                      data-target="#myModal">Enquire Now</span>
                  </div>
                  <div class="at-property-dis effetGradient">
                    <h5>4 BHK-3465 sq.ft</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section shadow-sm lazyloaded">
      <span class="section-link" id="pricing"></span>
      <span class="head text-capitalize">Price</span>
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-8">
          <table class="table table-striped table-borderless border micro-price-table table-pricing">
            <thead>
              <tr>
                <th scope="col" class="border border-bottom-0 mb-w">Type</th>
                <th scope="col" class="border border-bottom-0 mb-w">SBA</th>
                <th scope="col" class="border border-bottom-0 border-right-0">
                  Price
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border border-left-0 border-top-0 border-bottom-0 price-type">
                  3 BHK
                </td>
                <td class="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  2640 sq.ft.
                  <small class="d-inline-block d-md-none">(SBA)</small>
                </td>
                <td class="price-amt">
                  <i class="mi mi-rs-light"></i> 2.75 Cr*
                </td>
                <td>
                  <button class="btn btn-sm btn-info effetGradient effectScale" data-toggle="modal"
                    data-target="#myModal">
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td class="border border-left-0 border-top-0 border-bottom-0 price-type">
                  4 BHK
                </td>
                <td class="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  3260 sq.ft
                  <small class="d-inline-block d-md-none">(SBA)</small>
                </td>
                <td class="price-amt">
                  <i class="mi mi-rs-light"></i> 3.5 Cr*
                </td>
                <td>
                  <button class="btn btn-sm btn-info effetGradient effectScale enqModal">
                    Price Breakup
                  </button>
                </td>
              </tr>
              <tr>
                <td class="border border-left-0 border-top-0 border-bottom-0 price-type">
                  4 BHK
                </td>
                <td class="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                  3465 sq.ft
                  <small class="d-inline-block d-md-none">(SBA)</small>
                </td>
                <td class="price-amt">
                  <i class="mi mi-rs-light"></i> 3.70 Cr*
                </td>
                <td>
                  <button class="btn btn-sm btn-info effetGradient effectScale enqModal">
                    Price Breakup
                  </button>
                </td>
                <tr>
                  <td class="border border-left-0 border-top-0 border-bottom-0 price-type">
                    4 BHK
                  </td>
                  <td class="border border-left-0 border-top-0 border-bottom-0 price-carpet">
                    3775 sq.ft
                    <small class="d-inline-block d-md-none">(SBA)</small>
                  </td>
                  <td class="price-amt">
                    <i class="mi mi-rs-light"></i> 3.98 Cr*
                  </td>
                <td>
                  <button class="btn btn-sm btn-info effetGradient effectScale enqModal">
                    Price Breakup
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4">
          <a href="#" class="text-decoration-none enqModal" data-toggle="modal" data-target="#enqModal">
            <div class="at-property-item shadow-sm border border-grey mt-1">
              <div class="at-property-img lazyloaded" data-expand="-1">
                <picture>
                  <source class="w-100 lazyloading" data-srcset="/assets/godrej_costing_details.webp" type="image/webp"
                    sizes="411px" srcset="/assets/godrej_costing_details.webp" />
                  <img data-sizes="auto" class="w-100 lazyautosizes lazyloaded"
                    data-srcset="/assets/godrej_costing_details.webp" sizes="411px"
                    srcset="/assets/godrej_costing_details.webp" />
                </picture>
                <div class="at-property-overlayer"></div>
                <span class="btn btn-default at-property-btn" data-toggle="modal" data-target="#myModal">Enquire
                  Now</span>
              </div>
              <div class="at-property-dis effetGradient">
                <h5>Complete Costing Details</h5>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
    <section class="section shadow-sm lazyloaded">
      <span class="section-link" id="gallery"></span>
      <span class="head text-capitalize">Gallery</span>
      <ul class="nav nav-pills mb-3" id="pills-tabgal" role="tablist">
        <li class="nav-item">
          <a class="nav-link text-uppercase ami-tab active" id="pills-gal-0" data-toggle="pill" href="#gal-0" role="tab"
            aria-controls="gal-0" aria-selected="true">Exterior</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-uppercase ami-tab" id="pills-gal-1" data-toggle="pill" href="#gal-1" role="tab"
            aria-controls="gal-1" aria-selected="false">Interior</a>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabgalContent">
        <div class="tab-pane fade active show" id="gal-0" role="tabpanel" aria-labelledby="pills-gal-0">
          <div class="photo-gallery">
            <div class="row photos">
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/exterior/image-1" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/exterior/image-1.jpg" />
                </a>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/exterior/image-2" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/exterior/image-2.jpg" />
                </a>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/exterior/image-3" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/exterior/image-3.jpg" />
                </a>
              </div>
               <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/image-4" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/exterior/image-4.jpg" />
                </a>
              </div>
              <!--<div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/img5.png" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/exterior/img5.png" />
                </a>
              </div> 
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/img6.png" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/exterior/img6.png" />
                </a>
              </div>  -->

            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="gal-1" role="tabpanel" aria-labelledby="pills-gal-1">
          <div class="photo-gallery">
            <div class="row photos">
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/interior/in_1.jpg" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/interior/in_1.jpg" />
                </a>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/interior/in_2.jpg" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/interior/in_2.jpg" />
                </a>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/interior/in_3.jpg" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/interior/in_3.jpg" />
                </a>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/interior/in_4.jpg" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/interior/in_4.jpg" />
                </a>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/interior/in_5.jpg" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/interior/in_5.jpg" />
                </a>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/interior/in_6.jpg" data-lightbox="photos">
                  <img class="img-fluid" src="../../assets/images/interior/in_6.jpg" />
                </a>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 item">
                <a href="assets/images/interior/in_7.jpg" data-lightbox="photos">
                  <img class="img-fluid" src="assets/images/interior/in_7.jpg" />
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section shadow-sm lazyloaded">
      <span class="section-link" id="amenities"></span>
      <span class="head text-capitalize">Amenities</span>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div id="testimonial-slider" class="owl-carousel">
              <div class="testimonial">
                <div class="pic">
                  <img src="/assets/amenities/backup.svg" />
                  <h3 class="title">Power Back Up</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/chess.svg" />
                  <h3 class="title">Indoor Games</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/landscape.svg" />
                  <h3 class="title">Landscape Garden & Park</h3>
                </div>
              </div>
              <div class="testimonial">
                <div class="pic">
                  <img src="/assets/amenities/carpark.svg" />
                  <h3 class="title">Surface Car Park</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/gym.svg" />
                  <h3 class="title">Gym</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/basketball-court.svg" />
                  <h3 class="title">Basketball Court</h3>
                </div>
              </div>
              <div class="testimonial">
                <div class="pic">
                  <img src="/assets/amenities/multipurposehall.svg" />
                  <h3 class="title">Maintenance Staff</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/water-supply.svg" />
                  <h3 class="title">Water Feature</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/swm.svg" />
                  <h3 class="title">Swimming Pool & Kids Pool</h3>
                </div>
              </div>
              <div class="testimonial">
                <div class="pic">
                  <img src="/assets/amenities/badminton.svg" />
                  <h3 class="title">Outdoor Sports Facilities</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/libaray.svg" />
                  <h3 class="title">Library</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/party.svg" />
                  <h3 class="title">Party Hall</h3>
                </div>
              </div>
              <div class="testimonial">
                <div class="pic">
                  <img src="/assets/amenities/seniorcitizen.svg" />
                  <h3 class="title">Senior Park</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/plant.svg" />
                  <h3 class="title">Community Garden</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/jogging.svg" />
                  <h3 class="title">Jogging Track</h3>
                </div>
              </div>
              <div class="testimonial">
                <div class="pic">
                  <img src="/assets/amenities/security.svg" />
                  <h3 class="title">24/7 Security</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/cctv.svg" />
                  <h3 class="title">CCTV Camera</h3>
                </div>
                <div class="pic">
                  <img src="/assets/amenities/sewage.svg" />
                  <h3 class="title">Sewage Treatment Plant</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section shadow-sm lazyloaded">
      <span class="section-link" id="sitevisit"></span>
      <span class="head text-capitalize">Virtual Site Tour</span>
      <a href="#" class="enqModal" data-toggle="modal" data-target="#myModal">
        <div class="at-property-item my-2 pt-md-0">
          <div class="at-property-img vsv-img lazyloaded" data-expand="-1">
            <img src="../../assets/images/sliding-images/image-4.jpg" width="100%" />
            <div class="vsv-text-bk">
              <div class="vsv-text-bg">
                <div class="vsv-icon lazyloaded"></div>
                <span class="text-uppercase h1 font-weight-bold mb-0 d-none d-lg-block">Virtual Tour</span>
                <span class="text-capitalize text-center d-none d-lg-block">Lansum Elena, Kokapet, Hyderabad
                </span>
              </div>
            </div>
          </div>
          <span class="d-block text-capitalize text-center d-sm-block d-md-none pt-2">Lansum Elena, Kokapet, Hyderabad</span>
        </div>
      </a>
    </section>
    <section class="section shadow-sm lazyloaded">
      <span class="section-link" id="location"></span>
      <span class="head text-capitalize">Location</span>
      <div class="row mb-3">
        <div class="col-md-7 col-sm-12">
          <span class="d-block section-heading-sub text-capitalize">Map View</span>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60914.477200844594!2d78.32993!3d17.404356!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9500576720bd%3A0x3810938280b048e!2sLansum%20Elena%20(Elena%20Residences)!5e0!3m2!1sen!2sus!4v1720627721455!5m2!1sen!2sus"
            width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="col-md-5 col-sm-12 lmap-div text-center">
          <span class="d-block section-heading-sub text-capitalize text-center">Location Map</span>
          <a data-lightbox="photos" href="../../assets/images/location/map_new.webp">
            <div class="at-property-item mb-2">
              <div class="at-property-img master-plan lazyloaded" data-expand="-1">
                <img src="../../assets/images/location/map_new.webp" />

                <div class="at-property-overlayer"></div>
                <span class="at-property-btn">View Location Map</span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            KIMS Hospital – 20 Mins
          </p>
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Care Hospital – 16 Mins
          </p>
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Oakridge School – 10 Mins
          </p>
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Goldkrest School – 10 Mins 
          </p> 
        </div>

        <div class="col-md-4">
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            ISB – 14 Mins
          </p>
           <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Shri Chaitanya Junior college – 15 Mins
          </p>
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            IKEA, Inorbit Mall – 20 Mins
          </p>
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Infosys,Wipro –10 Mins
          </p>
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Microsoft, WaveRock – 10 Mins
          </p>
        </div>

        <div class="col-md-4">
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Kohinoor IT Park – 19 Mins
          </p>
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Financial District – 6 Mins
          </p>
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Outer Ring Road – 8 Mins
          </p>
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Gachibowli – 11 Mins
          </p>
          <p class="my-2">
            <i class="fa fa-map-marker color-primary loc-icon"></i>
            Hi-Tech City – 15 Mins
          </p>
        </div> 
      </div>
    </section>
    <section class="section shadow-sm lazyloaded" id="developer">
      <div class="d-block pt-2 pb-1 text-center">
        <img class="about-image"  src="../../assets/images/Logo/elene-logo.png" />
      </div> 
      <span class="d-block section-heading-sub text-capitalize">About Lansum Propertie</span>
      <p>
        Lansum Properties is a renowned real estate developer known for its commitment to excellence and 
        innovation in the Indian real estate market. Founded with a vision to create iconic landmarks that 
        redefine urban living, Lansum Properties has garnered a reputation for delivering premium projects 
        that offer unparalleled quality and luxury.
        <br>
        <br>
        With a focus on customer satisfaction and adherence to stringent quality standards, Lansum Properties 
        has successfully executed a diverse portfolio of residential, commercial, and mixed-use developments 
        across key cities in India. Each project reflects the company’s ethos of blending contemporary design, 
        sustainable practices, and cutting-edge technology to create spaces that elevate the lifestyle of its 
        residents.
      </p>
    </section>

    <section class="section shadow-sm lazyloaded" id="developer">
      <h6>
        <strong>Disclaimer :</strong> The content is for information purposes only and does not constitute an 
        offer to avail of any service. Prices mentioned are subject to change without notice and properties 
        mentioned are subject to availability. Images for representation purposes only. This is the official 
        website of authorized marketing partner. We may share data with RERA registered brokers/companies for 
        further processing. We may also send updates to the mobile number/email id registered with us. 
        All Rights Reserved.
      </h6>
    </section>
  </div>
  <div class="micro-side text-center">
    <div class="micro-content">
      <span class="d-block form-heading font-weight-bold my-2">Pre-Register here for Best Offers</span>
      <form [formGroup]="registerForm" class="form-side" (submit)="sendEmail($event)">
        <mat-form-field class="example-full-width" hidden>
          <input matInput value="Elena" name="company" />
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Name" formControlName="username" name="name" required />
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <span matPrefix>+91 &nbsp;</span>
          <input type="tel" matInput placeholder="555-555-1234" maxlength="10" formControlName="phone" name="phone" required>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="E-Mail Address" formControlName="email" name="email" required/>
        </mat-form-field>
        <button type="submit" name="enq_formBtn" class="btn btn-info micro-form-btn mt-2 effetMoveGradient"
          (click)="submit('pre')" [disabled]="registerForm.invalid">
          Pre-Register Now
        </button>
      </form>
      <h6 class="animate__animated animate__bounce infinite mt-2" style="animation-duration: 3s">
        <span class="form-last-heading lazyloaded" style="padding: 2px 8px; background: #e8e8e8" data-expand="-1"><img
            class="lazyloaded" style="width: 13px" src="/assets/car.png" />
          Free cab facility for site visit</span>
      </h6>
    </div>
  </div>
  <div class="modal" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div *ngIf="!message" class="modal-body text-center">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
          <div class="modal-head d-none">
            <span class="modal-title">Title</span>
          </div>
          <div>
            <div class="flex-fill align-self-center">
              <img style="background-color: #5F4858;" src="../../assets/images/Logo/elene-logo.png" class="logo" />
              <!--<h2>Gardens By The Brook</h2>-->
              <span class="modal-title-secondary">Register here and Avail the
                <span class="text-danger">Best Offers!!</span></span>
                <form [formGroup]="registerForm" class="form-side" (submit)="sendEmail($event)">
                  <mat-form-field class="example-full-width" hidden>
                    <input matInput value="Elena" name="company" />
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="Name" formControlName="username" name="name" required />
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <span matPrefix>+91 &nbsp;</span>
                    <input type="tel" matInput placeholder="555-555-1234" maxlength="10" formControlName="phone" name="phone" required>
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <input matInput placeholder="E-Mail Address" formControlName="email" name="email" required/>
                  </mat-form-field>
                  <button type="submit" name="enq_formBtn" class="btn btn-info micro-form-btn mt-2 effetMoveGradient"
                    (click)="submit('pre')" [disabled]="registerForm.invalid">
                    Pre-Register Now
                  </button>
                </form>
                <br>
            </div>
          </div>
          <a href="tel:+917207184624" class="modal-call-btn"><i class="mi mi-call"></i> +917207184624</a>
        </div>
        <div *ngIf="message" id="card" class="animated fadeIn">
          <div id="upper-side">
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve">
              <path d="M131.583,92.152l-0.026-0.041c-0.713-1.118-2.197-1.447-3.316-0.734l-31.782,20.257l-4.74-12.65
            c-0.483-1.29-1.882-1.958-3.124-1.493l-0.045,0.017c-1.242,0.465-1.857,1.888-1.374,3.178l5.763,15.382
            c0.131,0.351,0.334,0.65,0.579,0.898c0.028,0.029,0.06,0.052,0.089,0.08c0.08,0.073,0.159,0.147,0.246,0.209
            c0.071,0.051,0.147,0.091,0.222,0.133c0.058,0.033,0.115,0.069,0.175,0.097c0.081,0.037,0.165,0.063,0.249,0.091
            c0.065,0.022,0.128,0.047,0.195,0.063c0.079,0.019,0.159,0.026,0.239,0.037c0.074,0.01,0.147,0.024,0.221,0.027
            c0.097,0.004,0.194-0.006,0.292-0.014c0.055-0.005,0.109-0.003,0.163-0.012c0.323-0.048,0.641-0.16,0.933-0.346l34.305-21.865
            C131.967,94.755,132.296,93.271,131.583,92.152z" />
              <circle fill="none" stroke="#ffffff" stroke-width="5" stroke-miterlimit="10" cx="109.486" cy="104.353"
                r="32.53" />
            </svg>
            <h3 id="status">Success</h3>
          </div>
          <div id="lower-side">
            <p id="message">
              Congratulations, your request has been successfully sent.
            </p>
            <button type="button" class="btn btn-default" id="contBtn" data-dismiss="modal">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ul class="mob-action nav nav-fill d-sm-block d-lg-none">
    <li class="nav-item" onclick="javascript:location.href='tel:+917207184624'"><span
        class="mi mi-call action-icon"></span> Call</li>
    <li class="nav-item">
        <a class="nav-link" href="#" data-toggle="modal" data-target="#myModal">
            Enquire
        </a>
    </li>
    <li class="nav-item"
    onclick="window.open('https://api.whatsapp.com/send?phone=+917207184624&amp;text=Hi! I\'m Interested Lansum Elena Kokapet, Hyderabad. Please share me the details.', '_blank');">
      <span class="mi mi-whatsapp action-icon">
        <span class="path1"></span><span class="path2"></span><span class="path3"></span></span> WhatsApp
    </li>
  </ul>

</main>