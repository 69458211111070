import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  registerForm!: FormGroup;
  subscriptions: Subscription[] = [];
  errors = '';
  user: any;
  message = false;
  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.registerForm = new FormGroup({
      company: new FormControl(''),
      username: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required]),
    });
  }

  

  submit(value: string): void {
    if (this.registerForm.invalid) {
      return;
    }
    const registerData = {
      company: this.registerForm.controls.company.value,
      username: this.registerForm.controls.username.value,
      phone: this.registerForm.controls.phone.value,
      email: this.registerForm.controls.email.value,
      roles: ['user'],
    };
    this.user = this.userService.register(registerData).subscribe(
      (result) => {
        if (result) {
          if (value === 'modal') {
            this.message = true;
          } else {
            this.registerForm.reset();
            const dialogRef = this.dialog.open(DialogContentExampleDialog);
            dialogRef.afterClosed().subscribe((data) => { });
          }
        }
      },
      (e) => {
        return (this.errors = e.error);
      }
    );
  }

  public sendEmail(e: Event) {
    e.preventDefault();
    
    emailjs.sendForm('service_la4b2li', 'template_b4ev4qo', e.target as HTMLFormElement, 'MyHAPjR4I4YGC3js1')
      .then((result: EmailJSResponseStatus) => {
        console.log('Success');
        alert("Submitted successfully!");
        this.registerForm.reset();
        location.reload();
      }, (error) => {
        console.log('Failed');
      });
      
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogContentExampleDialog {}
