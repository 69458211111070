import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = '';
  constructor(private titleService: Title,
              private activatedRoute: ActivatedRoute,
              private router: Router) {}

  setDocTitle(title: string): void {
     console.log('current title:::::' + this.titleService.getTitle());
     this.titleService.setTitle(title);
  }
  // tslint:disable-next-line:typedef
  ngOnInit(){
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild ;
          if (child !== null){
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }
          return appTitle;
        }
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
}
}
